<template>
    <div class="filter-block" style="overflow:hidden" v-if="showFilter">
        <div class="hbox space-between">
            <div class="awesomeMediBtn filterBtnBig" @click="submitFilterSettings()">
                <span v-if="matchingProducts.requestPending">
                    Поиск...
                </span>

                <span v-else-if="!matchingProducts.requestPending && matchingProducts.count == null">
                    Показать товары
                </span>

                <span v-else-if="!matchingProducts.requestPending && matchingProducts.count == 0">
                    Товаров не найдено
                </span>

                <span v-else-if="!matchingProducts.requestPending">
                    Показать {{ matchingProducts.count }} {{ pluralize(matchingProducts.count, 'товар', 'товара', 'товаров') }}
                </span>
            </div>
            <div class="filterBtnSm blueVilet">
                <span
                    alt="Сбросить фильтры"
                    title="Сбросить фильтры"
                    @click="resetFilterSettings()"
                >
                    <i class="fa fa-refresh" aria-hidden="true"></i>
                </span>
            </div>
        </div>

        <template v-if="initPriceRange != null">
            <div class="filter-block-name">
                <span class="filter-block-param">
                    <span>Цена</span>
                </span>
            </div>

            <div class="openwin filter-block-checkbox">
                <vue-slider
                    v-model="filterSettings.priceRange"
                    :enable-cross="false"
                    :min="initPriceRange[0]"
                    :max="initPriceRange[1]"
                    :interval="1"
                />
            </div>
        </template>

        <div
            v-for="pn in paramNames"
            class="filter-block-item"
            :key="pn.id"
        >
            <div class="filter-block-name" @click="toggleFold(pn)">
                <span class="filter-block-param">
                    <div class="paramBlock">
                        <div v-html="pn.synonym ? pn.synonym : pn.name"></div>
                    </div>
                    <span
                        v-if="pn.type=='str' || pn.type=='variants'"
                        class="blueVilet"
                    >
                        <i
                            v-if="!filterSettings.paramsFolded[pn.id]"
                            class="fa-lg fa fa-caret-down"
                            aria-hidden="true"
                        ></i>
                        <i
                            v-if="filterSettings.paramsFolded[pn.id]"
                            class="fa-lg fa fa-caret-right"
                            aria-hidden="true"
                        ></i>
                    </span>
                </span>
            </div>

            <template v-if="!filterSettings.paramsFolded[pn.id] || pn.type=='num'">
                <!-- checkbox -->
                <div
                    v-if="pn.type=='str'"
                    class="openwin filter-block-checkbox"
                >
                    <ul class="spisok-parametrov">
                        <li
                            v-for="paramVariant in pn.paramVariants"
                            :key="paramVariant"
                            class="stringParamItem"
                            @click="toggleStrParamValue(pn, paramVariant)"
                        >
                            <input
                                type="checkbox"
                                class="stringParamItem__checkbox"
                                :checked="(strParamCheckboxes[pn.id] || [])[paramVariant]"
                            >
                            <span class="stringParamItem__value" v-html="paramVariant"></span>
                        </li>
                    </ul>
                </div>

                <!-- slider -->
                <div
                    v-if="pn.type=='num'"
                    class="openwin filter-block-checkbox"
                >
                    <vue-slider
                        v-model="filterSettings.numParamRanges[pn.id]"
                        :enable-cross="false"
                        :min="pn.paramLimits.minv"
                        :max="pn.paramLimits.maxv"
                        :interval="pn.interval"
                        :class="[
                            'rangeSlider',
                            {
                                'rangeSlider--inactive': !isNumParamActive(pn.id)
                            }
                        ]"
                        @change="activateNumParam(pn.id)"
                        @drag-start="activateNumParam(pn.id)"
                    />
                </div>

                <!-- select -->
                <div
                    v-if="pn.type=='variants'"
                    class="openwin filter-block-checkbox"
                >
                    <ul class="spisok-parametrov">
                        <li
                            v-for="paramVariant in pn.paramVariants"
                            :key="paramVariant.id"
                            class="variantParamItem"
                            @click="toggleVariantParamValue(pn, paramVariant.id)"
                        >
                            <input
                                type="checkbox"
                                class="variantParamItem__checkbox"
                                :checked="(variantParamCheckboxes[pn.id] || [])[paramVariant.id]"
                            >
                            <span class="variantParamItem__value">{{ paramVariant.value }}</span>
                        </li>
                    </ul>
                </div>
            </template>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .stringParamItem, .variantParamItem
    {
        padding: 2px 0;
        border-radius: 4px;
        user-select: none;
        cursor: pointer;

        &:hover
        {
            background-color: hsl(223deg, 61%, 90%);
        }

        &__checkbox, &__value
        {
            vertical-align: middle;
        }
    }

    .rangeSlider
    {
        &--inactive
        {
            filter: grayscale(1) opacity(0.45);
        }
    }
</style>

<script>
import isInteger from 'lodash.isinteger'
import cloneDeep from 'lodash.clonedeep'
import debouce from 'lodash.debounce'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import { submitForm, escapeUTF8 } from '../utils/form'
import { pluralize } from '../utils/pluralize'

export default {
    components: {
        VueSlider
    },

    props:
    {
        catId: Number,
        restoreState: Object
    },

    data: () =>
    ({
        paramNames: [],
        initNumParamRanges: {},
        initPriceRange: null,
        filterSettings:
        {
            numParamRanges: {},
            strParamValues: {},
            variantParamValues: {},
            priceRange: [],
            paramsFolded: {},
            activeNumParams: {}
        },
        matchingProducts: {
            count: null,
            requestPending: false,
            recountWhenParamsChange: false,
        },
        showFilter: false
    }),

    watch:
    {
        'filterSettings.numParamRanges': {
            deep: true,
            handler() {
                this.recountMatchingProducts();
            }
        },
        'filterSettings.strParamValues': {
            deep: true,
            handler() {
                this.recountMatchingProducts();
            }
        },
        'filterSettings.variantParamValues': {
            deep: true,
            handler() {
                this.recountMatchingProducts();
            }
        },
        'filterSettings.priceRange': {
            deep: true,
            handler() {
                this.recountMatchingProducts();
            }
        }
    },

    computed:
    {
        strParamCheckboxes()
        {
            const state = Object.fromEntries(
                Object.entries(this.filterSettings.strParamValues)
                    .map(([paramId, checkedValues]) => [
                        paramId,
                        Object.fromEntries(checkedValues.map(value => [value, true]))
                    ])
            )
            return state;
        },

        variantParamCheckboxes()
        {
            const state = Object.fromEntries(
                Object.entries(this.filterSettings.variantParamValues)
                    .map(([paramId, checkedValues]) => [
                        paramId,
                        Object.fromEntries(checkedValues.map(value => [value, true]))
                    ])
            )
            return state;
        }
    },

    created()
    {
        $.getJSON(`/catalog/getFilterData/${this.catId}/`)
            .done(response =>
            {
                this.paramNames = JSON.parse(JSON.stringify(response.params));
                if (this.paramNames.length > 0)
                    this.showFilter = true;

                if (response.price != null)
                    this.initPriceRange = [response.price.min, response.price.max];

                for (const prop of this.paramNames)
                {
                    prop.fold = false;

                    if ((prop.type == 'str' || prop.type == 'variants')
                        && prop.paramVariants.length > 10
                    )
                        this.filterSettings.paramsFolded[prop.id] = true;

                    if (prop.type == 'num')
                    {
                        let { minv, maxv } = prop.paramLimits;
                        // Convert to numbers for isInteger() to work correctly
                        minv = Number(minv);
                        maxv = Number(maxv);

                        if (!isInteger(minv) || !isInteger(maxv))
                        {
                            minv = Math.round(minv*100)/100;
                            maxv = Math.round(maxv*100)/100
                            if (((maxv * 100) % 10) > 0 || ((minv * 100) % 10) > 0)
                                prop.interval = 0.01
                            else
                                prop.interval = 0.1
                        } else
                        {
                            prop.interval = 1;
                        }

                        prop.paramLimits = { minv, maxv };
                        this.initNumParamRanges[prop.id] = [minv, maxv];
                        this.filterSettings.paramsFolded[prop.id] = false;
                    }
                }

                this.resetFilterSettings(); // sets initial num param ranges - crucial to do, even if we're restoring filter settings in the next step
                if (this.restoreState != null && this.restoreState.settings != null)
                {
                    const restoreSettings = cloneDeep(this.restoreState.settings);
                    this.filterSettings = {
                        ...this.filterSettings,
                        ...{
                            ...restoreSettings,
                            // replace only those ranges, that came from restoreState. And keep all others from current filterSettings, because it contains ranges for all num params, not only active ones
                            numParamRanges: {
                                ...this.filterSettings.numParamRanges,
                                ...restoreSettings.numParamRanges
                            }
                        }
                        };
                }

                // Ignore first param changes while restoring filter settings and start reacting to it afterwards
                // (use $nextTick() to give vue some time to apply those initial changes)
                this.$nextTick(() => this.matchingProducts.recountWhenParamsChange = true);
            })
            .fail((error) => console.log('An error occurred: ' + error));

            if (this.restoreState != null && this.restoreState.matchingProducts != null)
            {
                const restoreMatchingProducts = cloneDeep(this.restoreState.matchingProducts);
                this.matchingProducts = {
                    ...this.matchingProducts,
                    ...restoreMatchingProducts
                };
            }

            // Rate-limited function to count matching products. It ignores series of frequent param changes (e.g. when moving slider of a num param) and calls count function only after 150 ms from the last change.
            // @see: https://css-tricks.com/debouncing-throttling-explained-examples/
            this.debouncedCountMatchingProducts = debouce(() => this.countMatchingProducts(), 150);
    },

    methods:
    {
        toggleFold(pn)
        {
            const paramsFolded = cloneDeep(this.filterSettings.paramsFolded);
            paramsFolded[pn.id] = !paramsFolded[pn.id];
            this.$set(this.filterSettings, 'paramsFolded', paramsFolded);
        },

        toggleStrParamValue(param, value, checked)
        {
            let checkedValues = this.filterSettings.strParamValues[param.id];
            checkedValues = checkedValues || [];

            const idx = checkedValues.indexOf(value);
            checked = checked != null
                ? checked
                : !((this.strParamCheckboxes[param.id] || [])[value] || false)

            if (idx > -1 && checked === false)
            {
                checkedValues.splice(idx, 1);
            }
            else if (idx === -1 && checked === true)
            {
                checkedValues.push(value);
            }

            this.$set(this.filterSettings.strParamValues, param.id, checkedValues);
        },

        toggleVariantParamValue(param, variantId, checked)
        {
            let checkedValues = this.filterSettings.variantParamValues[param.id];
            checkedValues = checkedValues || [];

            const idx = checkedValues.indexOf(variantId);
            checked = checked != null
                ? checked
                : !((this.variantParamCheckboxes[param.id] || [])[variantId] || false)

            if (idx > -1 && checked === false)
            {
                checkedValues.splice(idx, 1);
            }
            else if (idx === -1 && checked === true)
            {
                checkedValues.push(variantId);
            }

            this.$set(this.filterSettings.variantParamValues, param.id, checkedValues);
        },

        activateNumParam(paramId)
        {
            this.filterSettings.activeNumParams[paramId] = true;
        },

        isNumParamActive(paramId)
        {
            return this.filterSettings.activeNumParams[paramId] === true;
        },

        countMatchingProducts()
        {
            const filterSettings = this.prepareFilterSettings();
            this.matchingProducts.requestPending = true;
             $.ajax({
                 method: 'post',
                url: `/catalog/countFilterMatchingProducts/${this.catId}/`,
                data: { filterSettings },
                dataType: 'json'
            })
                .done(response => {
                    this.matchingProducts.count = response.count;
                })
                .fail((error) => {
                    console.log('An error occurred: ' + error);
                })
                .always(() => {
                    this.matchingProducts.requestPending = false;
                });
        },

        recountMatchingProducts()
        {
            // If we are already counting products, wait to finish and count again
            if (this.matchingProducts.requestPending)
            {
                setTimeout(() => this.recountMatchingProducts(), 250);
                return;
            }

            if (this.matchingProducts.recountWhenParamsChange)
            {
                this.debouncedCountMatchingProducts();
            }
        },

        prepareFilterSettings()
        {
            const { strParamValues, variantParamValues, priceRange, paramsFolded, activeNumParams } = this.filterSettings;
            let { numParamRanges } = this.filterSettings;

            // Leave only those num params, which are marked as active
            numParamRanges = Object.fromEntries(
                Object.entries(numParamRanges)
                    .filter(([paramId]) => this.isNumParamActive(paramId))
            );

            let filterSettings = {
                numParamRanges,
                strParamValues,
                variantParamValues,
                priceRange,
                paramsFolded,
                activeNumParams
            }
            // Escape utf-8 characters for php's json_decode() function to correctly decode it
            filterSettings = escapeUTF8(JSON.stringify(filterSettings, null, 4));
            return filterSettings;
        },

        submitFilterSettings()
        {
            const filterSettings = this.prepareFilterSettings();
            submitForm(location.pathname, { filterSettings });
        },

        resetFilterSettings()
        {
            this.filterSettings = {
                ...this.filterSettings,
                numParamRanges: cloneDeep(this.initNumParamRanges),
                strParamValues: {},
                variantParamValues: {},
                priceRange: cloneDeep(this.initPriceRange),
                activeNumParams: {}
            };
        },

        pluralize
    }
}
</script>
<style>
    .paramBlock
    {
        white-space: nowrap;
    }
    .paramBlock > div
    {
        text-overflow: ellipsis;
        overflow: hidden;
        width: 193px;
    }
</style>