var Catalog;
var mainDiv = $('#varContent');

Catalog = function()
{
    /* show product thumbnails on icon over */
    $('.productImgTd .productIcon').mouseover(function(e)
    {
        var td = this.parentNode;
        var img = $(".productImg", td);
        var imgNode = img[0];

        img.load(function()
        {  // for product img
            if (imgNode.naturalWidth > imgNode.naturalHeight)
            {
                newWidth = 170;
                imgNode.style.width = newWidth+'px';
                imgNode.style.height = (newWidth * imgNode.naturalHeight / imgNode.naturalWidth) + 'px';
            } else
            {
                newHeight = 170;
                imgNode.style.height = newHeight+'px';
                imgNode.style.width = (newHeight * imgNode.naturalWidth / imgNode.naturalHeight) + 'px';
            }

            imgNode.style.marginLeft = (-imgNode.clientWidth-20)+'px';
            imgNode.style.marginTop = (-imgNode.clientHeight/2)+'px';
        });

        // for preloader img
        mainDiv.css('overflow', 'visible');
        img.removeClass('none');
        img.fadeIn(300);

        imgNode.style.width = 150+'px';
        imgNode.style.height = 150+'px';
        imgNode.style.left = 'auto';
        imgNode.style.marginLeft = (-imgNode.clientWidth-20)+'px';
        imgNode.style.marginTop = (-imgNode.clientHeight/2)+'px';

        imgNode.src = '/images/products/'+img.data('id')+'/centi.jpg';
    });

    /* hide product thumbnail on icon out */
    $(".productImgTd img").mouseout(function(e)
    {
        var td = this.parentNode;
        var img = $(".productImg", td);
        var imgNode = img[0];
        img.fadeOut(10, function()
        {
            /* Убираем изображение за границу экрана, чтобы оно не загораживало другие элементы */
            imgNode.style.left = -9999 + 'px';
            mainDiv.css('overflow','hidden');
        })
    });
}

Catalog.hasAncestor = function(element, ancestor)
{
    if (element == document) {return false; }
    if (element == ancestor) {return true; }
    if (element.parentNode == null) {return false;}
    else
    {
        return this.hasAncestor(element.parentNode, ancestor);
    }
}

Catalog.goToProduct = function(e, productId)
{
    if (e.target.className.indexOf('btn') == -1)
    {
        location.href='/catalog/product/'+productId+'/';
    }
}

Catalog.goToRentProduct = function(e, rentedId)
{
    if (e.target.className.indexOf('btn') == -1)
    {
        location.href='/leasing/product/'+rentedId+'/';
    }
}

Catalog.goToSubcatalog = function(e, subcatologId, subcatalogTranslit)
{
    location.href='/catalog/show/'+subcatologId+'-'+subcatalogTranslit+'/';
}

Catalog.goToRentSubcatalog = function(e, subcatologId)
{

    location.href='/leasing/showSubCatsNProds/'+subcatologId+'/';
}

Catalog.goToBrandSubcatalog = function(e, brandId, catId)
{
    location.href='/brands/products/'+brandId+'/'+catId+'/';
}

$(function(){ Catalog() });