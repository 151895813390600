<template>
    <div class="filter-block" style="overflow:hidden">
        <div class="gabarit">
            <span class="inviteText">Калькулятор для сотрудников! Эта страница не для клиента</span><br>
            Габариты фасада: <br>
            <table class='gabaritTable tbl'>
                <tr>
                    <td>Высота, м</td>
                    <td>Факт.высота, м</td>
                    <td>Длина, м</td>
                    <td>Факт.длина, м</td>
                    <td>Ярус деревянных настилов</td>
                    <td>Ярус стальных настилов</td>
                    <td>Кол-во подъемов</td>
                    <td>Общая площадь лесов, м<sup>2</sup></td>
                </tr>
                <tr>
                    <td><input type='text' v-model.number="facad.height"><span class='regionFrom'></span></td>
                    <td>{{ factHeight }}<span class='regionFrom'></span></td>
                    <td><input type='text' v-model.number="facad.length"><span class='regionFrom'></span></td>
                    <td>{{ factLength }}<span class='regionFrom'></span></td>
                    <td><input type='text' v-model="facad.woodenDeckingTiers"><span class='regionFrom'></span></td>
                    <td><input type='text' v-model="facad.steelDeckingTiers"><span class='regionFrom'></span></td>
                    <td><input type='text' v-model="facad.climbs"><span class='regionFrom'></span></td>
                    <td>{{ square }}<span class='regionFrom'></span></td>
                </tr>
            </table>
        </div>

        <button 
            class="blueGreyBtn t2_16 mb10" 
            title="рассчитать" 
            @click="calculate"
        >
            <i class="fa fa-gears" aria-hidden="true"></i>
            Рассчитать
        </button>

        <!-- Graphic of sections -->
        <div class="grid-container" :style="{ width: sectionsHor * 64 + 'px' }">
            <div 
                class="grid-item" 
                v-for="(block, index) in totalBlocks" 
                :key="index"
            >
            {{ block }}
            </div>
        </div>

        <div>
            <table class="tbl">
                <thead>
                <tr>
                    <th>Элемент лесов</th>
                    <th>Стоимость, руб</th>
                    <th>Кол-во</th>
                    <th>Сумма, руб</th>
                    <th>Цена с %, руб</th>
                    <th>Сумма с %, руб</th>
                    <th>Вес элемента лесов, кг</th>
                    <th>Вес в комплекте элементов, кг</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(product, name) in scufProds" :key="name"> 
                    <td>{{ product.title }}</td>
                    <td>{{ product.cost }}</td>
                    <td>{{ product.amount }}</td>
                    <td>{{ product.total }}</td>
                    <td>{{ product.price }}</td>
                    <td>{{ product.markedupTotal }}</td>
                    <td>{{ product.weight }}</td>
                    <td>{{ product.completeWeight }}</td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td>сумма</td>
                    <td class="t2 bluishBG white">{{ sumTotal }} руб</td>
                    <td>сумма с наценкой</td>
                    <td class="t2 bluishBG white">{{ sumMarkedupTotal }} руб</td>
                    <td>вес комплекта</td>
                    <td class="t2 bluishBG white">{{ sumWeight }} кг</td>
                </tr>
                </tbody>
            </table>
        </div>

        <table style="width: 15%"><tr>
            <td>наценка</td>
            <td class="t2 bluishBG white">{{ markup * 100}} %</td>
        </tr></table>
    </div>
</template>

<style lang="scss" scoped>
</style>

<script>
import { pluralize } from '../utils/pluralize'

export default {
    data: () =>
    ({
        scufProds: {
            'frame_with_ladder': {
                title: 'Рама с лестницей  42х1,5',
            },
            
            'frame_without_ladder': {
                title: 'Рама без лестницы 42х1,5',
                amount: 0,
            },
            'diagonal': {
                title: 'Диагональ 3 метра',
                amount: 0,
            },
            'horizontal': {
                title: 'Горизонталь 3 метра',
                amount: 0,
            },
            'boot': {
                title: 'Башмак',
                amount: 0,
            },
            'decking_wood': {
                title: 'Настил деревян. 1х1 (3 шт)',
                amount: 0,
            },
            'decking_steel': {
                title: 'Настил стальной 3х0,3',
                amount: 0,
            },
            'carrier': {
                title: 'Кронштейн',
                amount: 0,
            },
            'rigel': {
                title: 'Ригель 3м',
                amount: 0,
            } 
        },
        facad: {height: 0, length: 0, woodenDeckingTiers: 0, steelDeckingTiers: 0, climbs: 0},
        markup: 0.1
    }),

    computed: {
        factHeight() { return Math.floor(this.facad.height / 2) * 2 },
        factLength() { return Math.floor(this.facad.length / 3) * 3 },

        square() { return this.factHeight * this.factLength },

        sectionsVer() { return this.factHeight / 2}, // Number of blocks vertically
        sectionsHor() { return this.factLength / 3}, // Number of blocks horizontally
        totalBlocks() {
            const blocks = []
            for (let i = 0; i < this.sectionsVer; i++) {
                for (let j = 0; j < this.sectionsHor; j++) {
                    blocks.push(`${i + 1}_${j + 1}`)
                }
            }
            return blocks
        },

        sumTotal() {
            let sum = 0
            for (const prodName in this.scufProds) {
                if (Object.hasOwnProperty.call(this.scufProds, prodName)) {
                    sum += this.scufProds[prodName].total
                }
            }
            return sum
        },
        sumMarkedupTotal() {
            let sum = 0
            for (const prodName in this.scufProds) {
                if (Object.hasOwnProperty.call(this.scufProds, prodName)) {
                    sum += this.scufProds[prodName].markedupTotal
                }
            }
            return sum
        },
        sumWeight() {
            let sum = 0
            for (const prodName in this.scufProds) {
                if (Object.hasOwnProperty.call(this.scufProds, prodName)) {
                    sum += this.scufProds[prodName].completeWeight
                }
            }
            return sum
        }
    },

    created()
    {
        $.getJSON(`/lesacalc/getLesaCalcCosts/`)
            .done(responseData =>
            {
                this.markup = responseData.markup;
                for (const [prodname, product] of Object.entries(responseData.prods)) 
                {
                    if (this.scufProds[prodname]) {
                        this.$set(
                            this.scufProds, 
                            prodname, 
                            {
                                ...this.scufProds[prodname], 
                                cost: product.cost, 
                                weight: product.weight,
                                price: product.cost * this.markup + product.cost}
                        )
                    }
                }
            })
            .fail((error) => console.log('An error occurred: ' + error))
    },

    methods:
    {
        calculate() {
            this.$set(
                this.scufProds.frame_with_ladder,
                'amount',
                this.factHeight / 2 * this.facad.climbs
            )

            this.$set(
                this.scufProds.frame_without_ladder,
                'amount',
                this.factHeight / 2 * (this.factLength / 3 + 1) - this.scufProds.frame_with_ladder.amount
            )

            this.$set(
                this.scufProds.boot,
                'amount',
                (this.sectionsHor + 1) * 2
            )

            this.$set(
                this.scufProds.decking_wood,
                'amount',
                this.factLength * this.facad.woodenDeckingTiers
            )

            this.$set(
                this.scufProds.decking_steel,
                'amount',
                this.factLength * this.facad.steelDeckingTiers
            )

            this.$set(
                this.scufProds.rigel,
                'amount',
                this.sectionsHor * 2 * this.facad.woodenDeckingTiers
            )

            this.$set(
                this.scufProds.diagonal,
                'amount',
                Math.ceil(this.sectionsVer * this.sectionsHor / 2)
            )

            this.$set(
                this.scufProds.horizontal,
                'amount',
                this.scufProds.diagonal.amount * 3 + this.sectionsHor
            )

            this.$set(
                this.scufProds.carrier,
                'amount',
                this.scufProds.diagonal.amount
            )

            for (const prodName in this.scufProds) {
                if (Object.hasOwnProperty.call(this.scufProds, prodName)) {
                    const prod = this.scufProds[prodName]
                    prod.total = prod.cost * prod.amount
                    prod.markedupTotal = Math.round(prod.price * prod.amount * 10) / 10
                    prod.completeWeight = Math.round(prod.weight * prod.amount * 10) / 10
                }
            }
        }
    }
}
</script>
<style scoped>
.grid-container {
    display: flex;
    flex-wrap: wrap; /* Automatically wrap items into new rows */
    gap: 0px; /* Space between blocks (this will be transparent) */
}

.grid-item {
    background-color: #ffffff; /* White background for each block */
    height: 40px;  /* Height of each block (2 units of height) */
    width: 60px;   /* Width of each block (3 units of length) */
    text-align: center;
    line-height: 40px; /* Center text vertically */
    color: #3498db; /* Blue text color for blocks */
    border: 2px solid #3498db; /* Blue border for each block */
    border-radius: 4px;
    font-size: 12px;
    margin: 0;
}

th:nth-child(1), td:nth-child(1) {
    width: 14%;
}
th:nth-child(2), td:nth-child(2) {
    width: 8%;
}
th:nth-child(3), td:nth-child(3) {
    width: 6%;
}
th:nth-child(4), td:nth-child(4) {
    width: 10%;
}
th:nth-child(5), td:nth-child(5) {
    width: 10%;
}
th:nth-child(6), td:nth-child(6) {
    width: 10%;
}
th:nth-child(7), td:nth-child(7) {
    width: 10%;
}
th:nth-child(8), td:nth-child(8) {
    width: 10%;
}

 .tbl td {
    text-align: center;
 }
</style>
