var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "filter-block", staticStyle: { overflow: "hidden" } },
    [
      _c("div", { staticClass: "gabarit" }, [
        _vm._v("\n        Габариты фасада: "),
        _c("br"),
        _vm._v(" "),
        _c("table", { staticClass: "gabaritTable tbl" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("tr", [
            _c("td", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.number",
                    value: _vm.facad.height,
                    expression: "facad.height",
                    modifiers: { number: true }
                  }
                ],
                attrs: { type: "text" },
                domProps: { value: _vm.facad.height },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.facad, "height", _vm._n($event.target.value))
                  },
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              }),
              _c("span", { staticClass: "regionFrom" })
            ]),
            _vm._v(" "),
            _c("td", [
              _vm._v(_vm._s(_vm.factHeight)),
              _c("span", { staticClass: "regionFrom" })
            ]),
            _vm._v(" "),
            _c("td", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.number",
                    value: _vm.facad.length,
                    expression: "facad.length",
                    modifiers: { number: true }
                  }
                ],
                attrs: { type: "text" },
                domProps: { value: _vm.facad.length },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.facad, "length", _vm._n($event.target.value))
                  },
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              }),
              _c("span", { staticClass: "regionFrom" })
            ]),
            _vm._v(" "),
            _c("td", [
              _vm._v(_vm._s(_vm.factLength)),
              _c("span", { staticClass: "regionFrom" })
            ]),
            _vm._v(" "),
            _c("td", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.facad.woodenDeckingTiers,
                    expression: "facad.woodenDeckingTiers"
                  }
                ],
                attrs: { type: "text" },
                domProps: { value: _vm.facad.woodenDeckingTiers },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.facad,
                      "woodenDeckingTiers",
                      $event.target.value
                    )
                  }
                }
              }),
              _c("span", { staticClass: "regionFrom" })
            ]),
            _vm._v(" "),
            _c("td", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.facad.steelDeckingTiers,
                    expression: "facad.steelDeckingTiers"
                  }
                ],
                attrs: { type: "text" },
                domProps: { value: _vm.facad.steelDeckingTiers },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.facad,
                      "steelDeckingTiers",
                      $event.target.value
                    )
                  }
                }
              }),
              _c("span", { staticClass: "regionFrom" })
            ]),
            _vm._v(" "),
            _c("td", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.facad.climbs,
                    expression: "facad.climbs"
                  }
                ],
                attrs: { type: "text" },
                domProps: { value: _vm.facad.climbs },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.facad, "climbs", $event.target.value)
                  }
                }
              }),
              _c("span", { staticClass: "regionFrom" })
            ]),
            _vm._v(" "),
            _c("td", [
              _vm._v(_vm._s(_vm.square)),
              _c("span", { staticClass: "regionFrom" })
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "blueGreyBtn t2_16 mb10",
          attrs: { title: "рассчитать" },
          on: { click: _vm.calculate }
        },
        [
          _c("i", {
            staticClass: "fa fa-gears",
            attrs: { "aria-hidden": "true" }
          }),
          _vm._v("\n        Рассчитать\n    ")
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "grid-container",
          style: { width: _vm.sectionsHor * 64 + "px" }
        },
        _vm._l(_vm.totalBlocks, function(block, index) {
          return _c("div", { key: index, staticClass: "grid-item" }, [
            _vm._v("\n        " + _vm._s(block) + "\n        ")
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("div", [
        _c("table", { staticClass: "tbl" }, [
          _vm._m(1),
          _vm._v(" "),
          _c(
            "tbody",
            [
              _vm._l(_vm.scufProds, function(product, name) {
                return _c("tr", { key: name }, [
                  _c("td", [_vm._v(_vm._s(product.title))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(product.amount))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(product.price))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(product.markedupTotal))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(product.weight))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(product.completeWeight))])
                ])
              }),
              _vm._v(" "),
              _c("tr", [
                _c("td"),
                _vm._v(" "),
                _c("td"),
                _vm._v(" "),
                _c("td", [_vm._v("сумма")]),
                _vm._v(" "),
                _c("td", { staticClass: "t2 bluishBG white" }, [
                  _vm._v(_vm._s(_vm.sumMarkedupTotal) + " руб")
                ]),
                _vm._v(" "),
                _c("td", [_vm._v("вес комплекта")]),
                _vm._v(" "),
                _c("td", { staticClass: "t2 bluishBG white" }, [
                  _vm._v(_vm._s(_vm.sumWeight) + " кг")
                ])
              ])
            ],
            2
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", [_vm._v("Высота, м")]),
      _vm._v(" "),
      _c("td", [_vm._v("Факт.высота, м")]),
      _vm._v(" "),
      _c("td", [_vm._v("Длина, м")]),
      _vm._v(" "),
      _c("td", [_vm._v("Факт.длина, м")]),
      _vm._v(" "),
      _c("td", [_vm._v("Ярус деревянных настилов")]),
      _vm._v(" "),
      _c("td", [_vm._v("Ярус стальных настилов")]),
      _vm._v(" "),
      _c("td", [_vm._v("Кол-во подъемов")]),
      _vm._v(" "),
      _c("td", [_vm._v("Общая площадь лесов, м"), _c("sup", [_vm._v("2")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Элемент лесов")]),
        _vm._v(" "),
        _c("th", [_vm._v("Кол-во")]),
        _vm._v(" "),
        _c("th", [_vm._v("Цена, руб")]),
        _vm._v(" "),
        _c("th", [_vm._v("Сумма, руб")]),
        _vm._v(" "),
        _c("th", [_vm._v("Вес элемента лесов, кг")]),
        _vm._v(" "),
        _c("th", [_vm._v("Вес в комплекте элементов, кг")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }