/**
 * sends a request to the specified url from a form. this will change the window location.
 * @param {string} path the path to send the post request to
 * @param {object} params the parameters to add to the url
 * @param {string} [method=post] the method to use on the form
 */
// @link: https://stackoverflow.com/questions/133925/javascript-post-request-like-a-form-submit/133997#133997
export function submitForm(path, params, method = 'post')
{
    // The rest of this code assumes you are not using a library.
    // It can be made less verbose if you use one.
    const form = document.createElement('form')
    form.method = method
    form.action = path

    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            const hiddenField = document.createElement('input')
            hiddenField.type = 'hidden'
            hiddenField.name = key
            hiddenField.value = params[key]

            form.appendChild(hiddenField)
        }
    }

    document.body.appendChild(form)
    form.submit()
}

export function escapeUTF8(string) {
    return string.replace(/[\u007f-\uffff]/g, char => 
        '\\u' + ('0000' + char.charCodeAt(0).toString(16)).slice(-4)
    );
}