function openAnalogsBox(products, event)
{
	/* products[productId] = {'thambnail': , 'title': , 'price': } */
	$('div.sc_menu').empty();
	var scrollableInner = $("div.sc_menu");

	for (var productId in products)
	{
		var productInfo = products[productId];
   		var analogItem = $('<div />', {'class': 'analogItem'}).appendTo(scrollableInner);

   		$('<img />', {'class': 'analogImg', 'src': '/images/products/'+productInfo.product_id+'/milli.jpg'}).appendTo(analogItem);
   		var analogTitlePrice = $('<div />', {'class': 'analogTitlePrice'}).appendTo(analogItem);
   		$('<div />', {'class': ' analogTitle', 'html': productInfo.title}).appendTo(analogTitlePrice);
   		$('<div />', {'class': 'analogPrice', 'html': 'Цена: <b>'+productInfo.price+'</b> руб.'}).appendTo(analogTitlePrice);

   		analogItem.click( (function(productId)
   		{
   			return function()
   			{
   				var url = '/catalog/product/' + productId;
					window.location = url;
   			}
   		})(productInfo.product_id) );
	}

	var analogsObj = $("div.sc_menu_wrapper").css('display', 'block');
	var analogs = analogsObj[0];
	var analogsInner = $("div.sc_menu");
	var analogsRealHeight = Math.min(analogsInner.height(), analogsObj.height());
	var analogTop = event.pageY-analogs.parentNode.offsetTop-analogs.parentNode.parentNode.offsetTop-analogsRealHeight;
	analogsObj.css('top', analogTop + 'px');
	var analogLeft = event.pageX-analogs.parentNode.offsetLeft-analogs.parentNode.parentNode.offsetLeft+200;
	analogsObj.css('left', analogLeft + 'px');

	makeScrollable("div.sc_menu_wrapper", "div.sc_menu");

	var clickHandler = $(window).click(function(e) {
		if ($(e.target).hasClass('analogLink') || $.contains(analogs, e.target))
			return;

		$('div.sc_menu').empty();
		clickHandler.remove();
		escHandler.remove();
	});

	var escHandler = $(window).keyup( function(e)
	{
		/* Escape */
		if (e.keyCode == 27)
		{
			$('div.sc_menu').empty();
			clickHandler.remove();
			escHandler.remove();
		}
	});

	function makeScrollable(wrapper, scrollable)
	{
        // Get jQuery elements
	    var wrapper = $(wrapper), scrollable = $(scrollable);

	    // Hide images until they are not loaded
	    scrollable.hide();
	    var loading = $("<div class='loading'></div>").appendTo(wrapper);

	    loading.hide();
        // Remove scrollbars
        wrapper.css({overflow: "hidden"});
        scrollable.slideDown("slow", function()
        {
          enable();
        });

		function enable()
		{
		  // height of area at the top at bottom, that don't respond to mousemove
		  var inactiveMargin = 100;
		  // Cache for performance
		  var wrapperWidth = wrapper.width();
		  var wrapperHeight = wrapper.height();
		  // Using outer height to include padding too
		  var scrollableHeight = scrollable.outerHeight() + 2*inactiveMargin;
		  // Do not cache wrapperOffset, because it can change when user resizes window
		  // We could use onresize event, but it&#39;s just not worth doing that
		  // var wrapperOffset = wrapper.offset();

		  //When user move mouse over menu
		  wrapper.mousemove(function(e){
		    var wrapperOffset = wrapper.offset();
		    // Scroll menu
		    var top = (e.pageY -  wrapperOffset.top) * (scrollableHeight - wrapperHeight) / wrapperHeight  - inactiveMargin;

		    if (top < 0){
		      top = 0;
		    }

		    wrapper.scrollTop(top);
		  });
		}
	}
}